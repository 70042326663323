import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Layout from "../components/Layout"
import { Helmet } from 'react-helmet'
import CardListing from "../components/BlogLising/CardsListing"
import SubscribeThankYou from "../components/SubscribeThankYou"

const SubThankYouPage = (props) => {
	const slug = props.data.allContentfulBlogPost.edges[0].node.slug
	const cardsData = props.data.allContentfulBlogPost.edges
	return (
	<Layout>
		<Helmet>
			<title>Urbian | Thank you for subscribing</title>
		</Helmet>
		
		<Container className="blog_post content-wrap">
			<SubscribeThankYou />
      <Row>
        <Col md="7" lg="6">
			   <h2>In the meantime why not dig into some of our most popular articles</h2>
        </Col>
      </Row>
      <CardListing cardsData={cardsData} />
     </Container>
	</Layout>
	)
}

export default SubThankYouPage


export const subcontentfulBlogCheckLatestQuery = graphql`
  query SubscribeblogCheckLatestWpQuery {
    allContentfulBlogPost(limit: 6, sort: {order: DESC, fields: createdDate}) {
      edges {
        node {
          slug
          pageTitle
          createdDate
          cardBlogListing {
            id
            titleCard
            imageCard {
              fluid(maxWidth: 800){
                ...GatsbyContentfulFluid_withWebp
              }
              id
            }
            descriptionCard {
              descriptionCard
              childMarkdownRemark {
                id
              }
            }
          }
          categories
        }
      }
    }
  }
`


