import React, {useState} from 'react'
import Image from 'gatsby-image'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Link } from 'gatsby'
import './styled.css'

import Card from 'react-bootstrap/Card'

const CardsListing = (data) => {
    const [postsToShow, setPostsToShow] = useState(6)
    let blogCardsData = data.cardsData
    const index = postsToShow;
    
    return (
      <>
        <Row>
          { blogCardsData.slice(0, index).map(card => {
            let cardData = card.node.cardBlogListing 
            
            return (
                <Link className="card col-md-6"
                  to={`/read/${card.node.slug}`}>
                  <Image fluid={cardData[0].imageCard.fluid} />
                  <Card.Body>
                    <Card.Title>
                      {cardData[0].titleCard}
                    </Card.Title>
                    <p>
                      {cardData[0].descriptionCard.descriptionCard}
                    </p>
                  </Card.Body>
                </Link>
            )
          })}
        </Row>
        { postsToShow < blogCardsData.length &&
          <WrapperShowMore>
              
                  <button
                    className="styled__Link-lfEBUk iUBhrC font-weight-normal"
                    onClick={() => setPostsToShow(postsToShow + 6)}
                  >
                    More posts
                    <svg
                      className="styled__Arrow-fdeFVt fRPwvC"
                      width="14"
                      height="10"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        stroke="#030303"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <path d="M8.5.964L13.036 5.5 8.5 10.036"></path>
                        <path
                          d="M12.5 5.5H.5"
                          strokeLinecap="square"
                        ></path>
                      </g>
                    </svg>
                  </button>
              
          </WrapperShowMore>
        }
      </>
    )
  }


export default CardsListing


const WrapperShowMore = styled.div`
  display:flex;
  flex-direction:row;
  aling-items:center;
  justify-content:center;
  button {
    border:none  !important;
    outline:none !important;
    color: #030303 !important;
    opacity:1 !important;
    margin:0 !important;
    
  }
`