import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Image from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

const SubscribeThankYou = (props) => {
    const SubscribeThankYouImage = useStaticQuery(graphql`
      query SubscribeThankYouImage {
        subhero: file(absolutePath: {regex: "/assets/images/ThanksVideoCall.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 2010) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <Row className="sp-5">
        <Col lg="6" className="mobile-sp-5">
          <Image sizes={SubscribeThankYouImage.subhero.childImageSharp.sizes} alt="Thank you for subscribing!"/>
        </Col>
        <Col lg="6">
            <h1>Thank you for subscribing!</h1>
            <p>Thanks so much for filing out our subscription form, you're now in the loop with our quartely newsletter</p>
        </Col>
      </Row>
    )
  }


export default SubscribeThankYou